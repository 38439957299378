import { fieldIsRequired } from "./helpers";
import { Button } from "../button";
import { batch, createRenderEffect, untrack } from "solid-js";
// import { CamelToSnakeCase } from "@qundus.tc/dev.typescript/env";
import type { ICollection } from "./_model";
import type { _Button } from "../button";

export type CamelToSnakeCase<
  S extends string,
  Sep extends string = "_",
  UppercaseIsNewWord extends boolean = false,
  Start extends boolean = true,
  PreviousLetterIsCapital = false
> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T>
      ? Start extends false
        ? UppercaseIsNewWord extends true
          ? Sep
          : PreviousLetterIsCapital extends false
          ? Sep
          : ""
        : ""
      : ""}${Lowercase<T>}${T extends Capitalize<T>
      ? CamelToSnakeCase<U, Sep, UppercaseIsNewWord, false, true>
      : CamelToSnakeCase<U, Sep, UppercaseIsNewWord, false, false>}`
  : S;

type ControlGroupOnChange<V extends ValueKeyCase, T extends ControlGroup = ControlGroup> = (
  actions: Pick<
    ReturnType<typeof createFormActions<V, T>>,
    | "getValues"
    | "getValuesSnakecase"
    | "getValuesKebabcase"
    | "getValuesSnakecaseAggressive"
    | "getValuesUppercase"
    | "getValuesKebabcaseAggressive"
    | "getValuesLowercase"
  >
) => void;
type ControlGroup = { [K: string]: ICollection<any, any> };
type ValueKeyCase = "same" | "snake" | "snake_aggressive" | "kebab" | "kebab_aggressive" | "lowercase" | "uppercase";
type IsValueKeyOfCase<T extends ValueKeyCase, V extends ValueKeyCase> = V extends T ? true : false;
type ValueKeyCaseType<K, V extends ValueKeyCase> = IsValueKeyOfCase<"snake", V> extends true
  ? CamelToSnakeCase<string & K>
  : IsValueKeyOfCase<"snake_aggressive", V> extends true
  ? CamelToSnakeCase<string & K, "_", true>
  : IsValueKeyOfCase<"kebab_aggressive", V> extends true
  ? CamelToSnakeCase<string & K, "-", true>
  : IsValueKeyOfCase<"kebab", V> extends true
  ? CamelToSnakeCase<string & K, "-">
  : IsValueKeyOfCase<"lowercase", V> extends true
  ? Lowercase<string & K>
  : IsValueKeyOfCase<"uppercase", V> extends true
  ? Uppercase<string & K>
  : // : IsValueKeyOfCase<"same", V> extends true
    K;
// : never;
type ControlGroupCaseMap<T extends ControlGroup> = {
  [K in keyof T]?: ValueKeyCase;
};
type GetValueKeuCase<V extends ValueKeyCase, Default extends ValueKeyCase> = unknown extends V
  ? Default
  : ValueKeyCase extends V
  ? Default
  : V;
type ControlGroupValues<T extends ControlGroup, V extends ValueKeyCase, O extends ControlGroupCaseMap<T>> = {
  [K in keyof T as ValueKeyCaseType<K, GetValueKeuCase<O[K], V>>]: T[K]["control"]["value"];
};

function camelToSnakeCase(str: string, joiner: string = "_", uppercaseIsNewWord: boolean = false): string {
  const regex = !uppercaseIsNewWord ? /[A-Z]+/g : /[A-Z]/g;
  return str.replace(regex, (match, index) => {
    // console.log("match :: ", match, "index :: ", index);
    return index === 0 ? match.toLowerCase() : `${joiner}${match.toLowerCase()}`;
  });
}

function getValueCase(key: string, convertCase: ValueKeyCase = "same") {
  if (convertCase === "snake") {
    return camelToSnakeCase(key, "_");
  } else if (convertCase === "snake_aggressive") {
    return camelToSnakeCase(key, "_", true);
  } else if (convertCase === "kebab") {
    return camelToSnakeCase(key, "-");
  } else if (convertCase === "kebab_aggressive") {
    return camelToSnakeCase(key, "-", true);
  } else if (convertCase === "lowercase") {
    return key.toLowerCase();
  } else if (convertCase === "uppercase") {
    return key.toUpperCase();
  }
  return key;
}

export default function createFormActions<V extends ValueKeyCase, T extends ControlGroup = ControlGroup>(
  controls: T,
  options?: {
    defaultCase?: V;
    onChange?: ControlGroupOnChange<V, T> | ControlGroupOnChange<V, T>[];
  }
) {
  const entries = Object.entries(controls);
  let submitted = false;
  const actions = {
    getValues<O extends ControlGroupCaseMap<T>>(special?: O) {
      return untrack(() => {
        const result = {};
        for (const key in controls) {
          const key_case = getValueCase(key, special?.[key] ?? "same");
          result[key_case] = controls[key].control.value;
        }
        return result as ControlGroupValues<T, "same", O>;
      });
    },
    getValuesLowercase<O extends ControlGroupCaseMap<T>>(special?: O) {
      return untrack(() => {
        const result = {};
        for (const key in controls) {
          const key_case = getValueCase(key, special?.[key] ?? "lowercase");
          result[key_case] = controls[key].control.value;
        }

        return result as ControlGroupValues<T, "lowercase", O>;
      });
    },
    getValuesUppercase<O extends ControlGroupCaseMap<T>>(special?: O) {
      return untrack(() => {
        const result = {};
        for (const key in controls) {
          const key_case = getValueCase(key, special?.[key] ?? "uppercase");
          result[key_case] = controls[key].control.value;
        }

        return result as ControlGroupValues<T, "uppercase", O>;
      });
    },
    getValuesSnakecase<O extends ControlGroupCaseMap<T>>(special?: O) {
      return untrack(() => {
        const result = {};
        for (const key in controls) {
          const key_case = getValueCase(key, special?.[key] ?? "snake");
          result[key_case] = controls[key].control.value;
        }

        return result as ControlGroupValues<T, "snake", O>;
      });
    },
    getValuesSnakecaseAggressive<O extends ControlGroupCaseMap<T>>(special?: O) {
      return untrack(() => {
        const result = {};
        for (const key in controls) {
          const key_case = getValueCase(key, special?.[key] ?? "snake_aggressive");
          result[key_case] = controls[key].control.value;
        }

        return result as ControlGroupValues<T, "snake_aggressive", O>;
      });
    },
    getValuesKebabcase<O extends ControlGroupCaseMap<T>>(special?: O) {
      return untrack(() => {
        const result = {};
        for (const key in controls) {
          const key_case = getValueCase(key, special?.[key] ?? "kebab");
          result[key_case] = controls[key].control.value;
        }

        return result as ControlGroupValues<T, "kebab", O>;
      });
    },
    getValuesKebabcaseAggressive<O extends ControlGroupCaseMap<T>>(special?: O) {
      return untrack(() => {
        const result = {};
        for (const key in controls) {
          const key_case = getValueCase(key, special?.[key] ?? "kebab_aggressive");
          result[key_case] = controls[key].control.value;
        }

        return result as ControlGroupValues<T, "kebab_aggressive", O>;
      });
    },
  };
  let values_tracker = actions.getValues();
  createRenderEffect((started) => {
    let change_occured = false;
    for (let [k, c] of Object.entries(controls)) {
      if (c.control.value && c.control.isValid && values_tracker[k] !== c.control.value) {
        change_occured = true;
        // if (!started) {
        //   break;
        // }
      }
    }
    if (!started) {
      if (change_occured) {
        untrack(() => {
          batch(() => {
            values_tracker = actions.getValues();
            if (typeof options?.onChange === "function") {
              options?.onChange(actions);
            } else {
              options?.onChange?.forEach((func) => {
                func(actions);
              });
            }
          });
        });
        // console.log("form is valid and changed");
      }
    } else {
      // console.log("form started ");
    }
    return undefined;
  }, "started");
  return {
    ...actions,
    onChange(funcs: ControlGroupOnChange<V, T> | ControlGroupOnChange<V, T>[]) {
      let result = typeof funcs === "function" ? [funcs] : funcs;
      if (!options) options = {};
      if (options.onChange) {
        if (typeof options.onChange === "function") {
          result = [options.onChange, ...result];
        } else {
          result = [...options.onChange, ...result];
        }
      }
      options.onChange = result;
    },
    update<O extends ControlGroupCaseMap<T>>(
      value: Partial<ControlGroupValues<T, V, O>>,
      special?: O,
      ignore?: (keyof T)[]
    ) {
      untrack(() => {
        batch(() => {
          for (const key in controls) {
            if (ignore?.includes(key)) {
              continue;
            }
            const control = controls[key];
            const key_case = getValueCase(key, special?.[key] ?? options?.defaultCase ?? "same");
            const key_descriptor = Object.getOwnPropertyDescriptor(value, key_case);
            if (!key_descriptor) {
              console.warn(
                "createFormActions: cannot update form key ",
                key,
                " -> with case -> ",
                key_case,
                " because it does not exist in the value object! "
              );
              continue;
            }
            const value_to_update = value[key_case];
            control.control.setValue(value_to_update);
            // console.log("updating :: ", key, " with :: ", key_case, " and value :: ", value_to_update);
            // console.log(
            //   "updating :: ",
            //   key,
            //   " with :: ",
            //   key_case,
            //   " with value :: ",
            //   value_to_update,
            //   " === ",
            //   control.control.value
            // );
          }
        });
      });
    },
    isSubmitted() {
      return submitted;
    },
    markSubmitted(e?: Event) {
      submitted = true;
      if (e) e.preventDefault();
    },
    canSubmit() {
      let result = true;
      for (const [, v] of entries) {
        if (!v.control.isValid) {
          result = false;
          break;
        } else {
          const err = fieldIsRequired(v.control).checkControl();
          if (err) {
            v.control.setErrors(err);
            result = false;
          }
        }
      }
      return result;
    },

    Button: (props: Omit<_Button.MainProps, "controls">) => <Button controls={controls} {...props} />,
  } as const;
}
